import React from "react"
// import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

class AboutPage extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      active: false,
    }
    this.toggleClass = this.toggleClass.bind(this)
  }
  toggleClass = (el) => {
    // console.log(el.target);
    el.target.classList.toggle("active")
    return false
  }

  render() {
    return (
      <Layout>
        <SEO title="About Iliuta Stoica" />

        <div className="box aboutWrapper">
          <div className="maxWidth">
            <div className="aboutContent">
              <h2 className="pageTitle">Intro</h2>
              <p className="contentText ">
                I have {new Date().getFullYear() - 2011}+ years of experience
                with frontend technologies. I found my passion in Computer
                Science during my high-school years and afterwards as a student.
                I’ve also worked as a web designer and a wordpress
                designer/developer and currently I’m a frontend developer, in
                Bucharest (RO).
              </p>
            </div>
            <div className="aboutContent">
              <h2 className="pageTitle">Skills &amp; Strenghts </h2>
              <p className="contentText">
                I have been working with the following technologies: React,
                Angular 9, JavaScript, jQuery, HTML, CSS, LESS, SASS, Parallax,
                Bootstrap, PHP, WordPress PHP, PWA, AMP. I’m effective with
                React, SASS, jQuery, Parallax, Animations and Responsive Design.
              </p>
              <p className="contentText ">
                My personal strengths are: <br />
                - proactivity <br />
                - dedication
                <br />
                - attention for details <br />
                - solution oriented <br />
                - ability to dive into the code and learn fast
                <br />
                - ability to manage multiple priorities and meet strict
                deadlines
                <br />
                - good knowledge of written and verbal communication abilities
                in English
                <br />
              </p>
              <p className="contentText ">
                I have experience with: <br />
                - code versioning tools: TurtoiseSVN / Git / GitLab / Github
                <br />
                - continuos integration: Gitlab CI
                <br />
                - package manager: Npm <br />
                - project management: JIRA / Asana / Scrum / Kanban
                <br />
                - page speed optimizations
                <br />
                - SEO, using Structured Data, Microformats, SEO rules
                <br />
                - AMP (accelerated mobile pages)
                <br />
                - coding tools: Sublime / Atom / WebStorm / VS Code
                <br />
                - design tools: Photoshop / Illustrator
                <br />
              </p>
            </div>
            <div className="aboutContent">
              <h2 className="pageTitle">Work Experience</h2>
              <ul className="expandList workExp">
                <li className="listItem">
                  <div className="bullet big">
                    <svg
                      aria-hidden="true"
                      viewBox="0 0 32 32"
                      focusable="false"
                    >
                      <path d="M16 4c6.6 0 12 5.4 12 12s-5.4 12-12 12S4 22.6 4 16 9.4 4 16 4zm0-4C7.2 0 0 7.2 0 16s7.2 16 16 16 16-7.2 16-16S24.8 0 16 0z"></path>
                      <circle cx="16" cy="16" r="6"></circle>
                    </svg>
                  </div>
                  <button
                    className={"ItemLink active"}
                    onClick={this.toggleClass}
                  >
                    <strong>Senior Frontend developer</strong>
                    <span> at Endava</span>
                    <span className="expandIcon">&#43;</span>
                  </button>
                  <div className="itemExpDetails">
                    <div className="itemSubDetails">
                      <span className="eventLocation">
                        <strong>Location</strong>: Bucharest, Romania
                      </span>
                      <span className="eventPeriod">
                        <strong>Period</strong>: 2020 - current
                      </span>
                    </div>
                    <p className="eventDesription">
                      Developing client specific projects. Working with:
                      <br />
                      - React <br />
                      - Angular 9 <br />
                      - Unity (for virtual 3d experience)
                      <br />
                      <br />
                      Main responsibilites and tasks:
                      <br />
                      - Building React &amp; Angular custom applications
                      <br />
                      - Creating responsive frontend interfaces for clients
                      <br />
                      - Creating 3D virtual experiences with Unity
                      <br />
                      - Working closely with designers and UX developers to
                      ensure the proper implementation of the projects
                      <br />
                    </p>
                  </div>
                </li>
                <li className="listItem">
                  <div className="bullet big">
                    <svg
                      aria-hidden="true"
                      viewBox="0 0 32 32"
                      focusable="false"
                    >
                      <path d="M16 4c6.6 0 12 5.4 12 12s-5.4 12-12 12S4 22.6 4 16 9.4 4 16 4zm0-4C7.2 0 0 7.2 0 16s7.2 16 16 16 16-7.2 16-16S24.8 0 16 0z"></path>
                      <circle cx="16" cy="16" r="6"></circle>
                    </svg>
                  </div>
                  <button className={"ItemLink"} onClick={this.toggleClass}>
                    <strong>Frontend developer</strong>
                    <span> at EveryMatrix</span>
                    <span className="expandIcon">&#43;</span>
                  </button>
                  <div className="itemExpDetails">
                    <div className="itemSubDetails">
                      <span className="eventLocation">
                        <strong>Location</strong>: Bucharest, Romania
                      </span>
                      <span className="eventPeriod">
                        <strong>Period</strong>: 2011 - 2019
                      </span>
                    </div>
                    <p className="eventDesription">
                      Developing and implementing the clients websites for
                      desktop and mobile versions using the following
                      tehnologies:
                      <br />
                      - HTML, CSS, CSS3, SASS / LESS, Animations, CSS VARS, PHP,
                      Wordpress, Responsive Design, jQuery, Parallax, JavaScript
                      Libraries <br />
                      - TurtoiseSVN, Git and Gitlab (for version control)
                      <br />
                      - Gitlab CI (for continous integration)
                      <br />
                      - Grunt, Gulp (for task runners)
                      <br />
                      - NPM, webpack, parcel (for application bundlers)
                      <br />
                      - SCRUM / Kanban (for project methodology)
                      <br />
                      - Jira / Asana (for project management)
                      <br />
                      <br />
                      Main responsibilites and tasks:
                      <br />
                      - Translating complex Photoshop designs to clean, semantic
                      markup with a high level of accuracy and attention to
                      detail
                      <br />
                      - Working closely with designers and UX developers to
                      ensure the proper implementation of the projects
                      <br />
                      - Optimizing performance, using SVG, encoding images with
                      base64, critical CSS
                      <br />
                      - Building modular and reusable front-end components
                      <br />
                      - Creating responsive frontend interfaces for clients
                      <br />
                      - Integrating 3rd party plugins
                      <br />
                      - Creating and developing Wordpress themes from scratch
                      <br />
                      - Developing user-friendly, fast, performant and smooth
                      mobile web applications
                      <br />
                      - Implementing custom landing pages with animations
                      <br />
                      - Creating templates for React components
                      <br />
                      - Creating AMP version for websites
                      <br />
                      <br />
                      Making the `impossible` and implementing and launching a
                      new client design in just three weeks, the fastest
                      integration in the history of EveryMatrix (December 2017).
                      Working closely together with other teams to integrate the
                      client on our platform. ( https://www.stake7.com/ )
                      <br />
                    </p>
                  </div>
                </li>
                <li className="listItem">
                  <div className="bullet ">
                    <svg
                      aria-hidden="true"
                      viewBox="0 0 32 32"
                      focusable="false"
                    >
                      <circle stroke="none" cx="16" cy="16" r="10"></circle>
                    </svg>
                  </div>
                  <button className={"ItemLink"} onClick={this.toggleClass}>
                    <strong>Wordpress Developer & Designer</strong>
                    <span> at Ascensys SRL</span>
                    <span className="expandIcon">&#43;</span>
                  </button>
                  <div className="itemExpDetails">
                    <div className="itemSubDetails">
                      <span className="eventLocation">
                        <strong>Location</strong>: Bucharest, Romania
                      </span>
                      <span className="eventPeriod">
                        <strong>Period</strong>: 2011
                      </span>
                    </div>
                    <p className="eventDesription">
                      Creating Custom CMS Websites for clients. Working mostly
                      with Wordpress for design and developing custom themes.
                      <br />
                      Working with: CSS3, LESS, jQuery, Parallax, Wordpress,
                      Photoshop.
                    </p>
                  </div>
                </li>
                <li className="listItem">
                  <div className="bullet ">
                    <svg
                      aria-hidden="true"
                      viewBox="0 0 32 32"
                      focusable="false"
                    >
                      <circle stroke="none" cx="16" cy="16" r="10"></circle>
                    </svg>
                  </div>
                  <button className={"ItemLink"} onClick={this.toggleClass}>
                    <strong>Wordpress Developer </strong>
                    <span> at Innobyte</span>
                    <span className="expandIcon">&#43;</span>
                  </button>
                  <div className="itemExpDetails">
                    <div className="itemSubDetails">
                      <span className="eventLocation">
                        <strong>Location</strong>: Bucharest, Romania
                      </span>
                      <span className="eventPeriod">
                        <strong>Period</strong>: 2010
                      </span>
                    </div>
                    <p className="eventDesription">
                      Creating Custom CMS Websites for clients. Working mostly
                      with Wordpress for design and developing custom themes.
                      <br />
                      Working with: CSS3, LESS, jQuery, Parallax, Wordpress,
                      Photoshop.
                    </p>
                  </div>
                </li>
                <li className="listItem">
                  <div className="bullet ">
                    <svg
                      aria-hidden="true"
                      viewBox="0 0 32 32"
                      focusable="false"
                    >
                      <circle stroke="none" cx="16" cy="16" r="10"></circle>
                    </svg>
                  </div>
                  <button className={"ItemLink"} onClick={this.toggleClass}>
                    <strong>Junior frontend developer </strong>
                    <span> at Media10</span>
                    <span className="expandIcon">&#43;</span>
                  </button>
                  <div className="itemExpDetails">
                    <div className="itemSubDetails">
                      <span className="eventLocation">
                        <strong>Location</strong>: Bucharest, Romania
                      </span>
                      <span className="eventPeriod">
                        <strong>Period</strong>: 2010
                      </span>
                    </div>
                    <p className="eventDesription">
                      Working mostly with Photoshop for slicing design
                      materials, HTML/CSS for developing custom web elements.
                    </p>
                  </div>
                </li>
              </ul>
            </div>
            <div className="aboutContent">
              <h2 className="pageTitle">Education</h2>
              <ul className="expandList workExp">
                <li className="listItem">
                  <div className="bullet big">
                    <svg
                      aria-hidden="true"
                      viewBox="0 0 32 32"
                      focusable="false"
                    >
                      <path d="M16 4c6.6 0 12 5.4 12 12s-5.4 12-12 12S4 22.6 4 16 9.4 4 16 4zm0-4C7.2 0 0 7.2 0 16s7.2 16 16 16 16-7.2 16-16S24.8 0 16 0z"></path>
                      <circle cx="16" cy="16" r="6"></circle>
                    </svg>
                  </div>
                  <button className={"ItemLink"} onClick={this.toggleClass}>
                    <strong>Mobile Web Specialist NanoDegree</strong>
                    <span> at Udacity</span>
                    <span className="expandIcon">&#43;</span>
                  </button>
                  <div className="itemExpDetails">
                    <div className="itemSubDetails">
                      <span className="eventPeriod">
                        <strong>Period</strong>: 2018
                      </span>
                    </div>
                    <p className="eventDesription">
                      Build a responsive web app that functions on any device or
                      screen size. Ensure your web apps meet the highest
                      development by achieving accessibility standards. Create a
                      total user experience leveraging asynchronous browser
                      features, local storage, and more.
                    </p>
                  </div>
                </li>
                <li className="listItem">
                  <div className="bullet ">
                    <svg
                      aria-hidden="true"
                      viewBox="0 0 32 32"
                      focusable="false"
                    >
                      <circle stroke="none" cx="16" cy="16" r="10"></circle>
                    </svg>
                  </div>
                  <button className={"ItemLink"} onClick={this.toggleClass}>
                    <strong>Master's Degree</strong>
                    <span> at ETTI, Politehnica University</span>
                    <span className="expandIcon">&#43;</span>
                  </button>
                  <div className="itemExpDetails">
                    <div className="itemSubDetails">
                      <span className="eventLocation">
                        <strong>Location</strong>: Bucharest, Romania
                      </span>
                      <span className="eventPeriod">
                        <strong>Period</strong>: 2012 - 2014
                      </span>
                    </div>
                    <p className="eventDesription">
                      The Master's Degree was in Information Technology at the
                      Faculty of Electronics, Telecommunications and Information
                      Technology (ETTI), Politehnica University. The curriculum
                      was in Inteligent Systems and Computer Vision.
                    </p>
                  </div>
                </li>
                <li className="listItem">
                  <div className="bullet ">
                    <svg
                      aria-hidden="true"
                      viewBox="0 0 32 32"
                      focusable="false"
                    >
                      <circle stroke="none" cx="16" cy="16" r="10"></circle>
                    </svg>
                  </div>
                  <button className={"ItemLink"} onClick={this.toggleClass}>
                    <strong>Bachelor Degree </strong>
                    <span> at Ghe. Asachi University</span>
                    <span className="expandIcon">&#43;</span>
                  </button>
                  <div className="itemExpDetails">
                    <div className="itemSubDetails">
                      <span className="eventLocation">
                        <strong>Location</strong>: Iasi, Romania
                      </span>
                      <span className="eventPeriod">
                        <strong>Period</strong>: 2005- 2009
                      </span>
                    </div>
                    <p className="eventDesription">
                      The Bachelor Degree, was in Automatics at the Computer
                      Science Faculty at Gheorghe Asachi Technical University.
                      The license paper objectives were in 3D Stereovision with
                      automated robots.
                    </p>
                  </div>
                </li>
              </ul>
            </div>
            <div className="aboutContent">
              <h2 className="pageTitle">Hobbies</h2>
              <p className="contentText ">
                In my spare time I like to read, keep up with the latest trends
                in technology, tinker around on various projects and some
                gardening.
              </p>
            </div>
          </div>
        </div>
      </Layout>
    )
  }
}

export default AboutPage
